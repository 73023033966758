import React from "react";
import { getStock, GridConfig, StockData, stockIn, stockOut } from "../../api/stock";
import { MDBBtn, MDBInput, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import useLocale from "../../util/i18n";
import { useCrumbs } from "../../component/breadcrumbs";
import Toast from "../../component/toast";
import { parseSku } from "../../util/misc";
import { saveConfig } from "../../api/core";

export default function Stock() {
    let [data, setData] = React.useState<{ [key: string]: StockData }>({});
    let [config, setConfig] = React.useState<Array<GridConfig>>([]);
    let [setCrumbs] = useCrumbs();
    let [__] = useLocale();
    let doStockOut = React.useCallback((position: Array<[number, number, number]>) => {
        let cloned = { ...data }
        for (const p of position) {
            delete cloned[p.join('-')];
        }
        setData(cloned);
        stockOut({ position }).catch(() => {
            setData(data);
            Toast.show(__('An error detected. Please try again later.'), 'danger');
        });
    }, [data, setData]);
    let doStockIn = React.useCallback((area: number, row: number, col: number) => {
        let sku = window.prompt(__('Please enter the SKU to stock in.'));
        if (sku) {
            let cloned = { ...data }
            cloned[area + '-' + row + '-' + col] = { area, row, col, sku, qty: 1 };
            setData(cloned);
            stockIn({ area, row, col, sku, qty: 1 }).catch(() => {
                setData(data);
                Toast.show(__('An error detected. Please try again later.'), 'danger');
            });
        }
    }, [data, setData, __]);
    const configRef = React.useRef<ConfigRef>(null);
    let doSaveConfig = React.useCallback((data: GridConfig, index: number) => new Promise<void>(resolve => {
        let cloned = [...config], bak = [...config];
        cloned[index] = { ...(cloned[index] || {}), ...data };
        setConfig(cloned);
        saveConfig({ path: 'stock/box', value: JSON.stringify(cloned) }).then(() => resolve(), () => {
            Toast.show(__('An error detected. Please try again later.'), 'danger');
            setConfig(bak);
        });
    }), [config, setConfig]);
    let doEnable = React.useCallback((area: number, row: number, col: number) => {
        let cloned = [...config], bak = [...config];
        cloned[area].disabled ??= [];
        let target = cloned[area].disabled.findIndex(i => i[0] == row && i[1] == col);
        cloned[area].disabled.splice(target, 1);
        setConfig(cloned);
        saveConfig({ path: 'stock/box', value: JSON.stringify(cloned) }).catch(() => {
            Toast.show(__('An error detected. Please try again later.'), 'danger');
            setConfig(bak);
        });
    }, [config, setConfig]);
    let doDisable = React.useCallback((area: number, row: number, col: number) => {
        let cloned = [...config], bak = [...config];
        cloned[area].disabled ??= [];
        cloned[area].disabled.push([row, col]);
        setConfig(cloned);
        saveConfig({ path: 'stock/box', value: JSON.stringify(cloned) }).catch(() => {
            Toast.show(__('An error detected. Please try again later.'), 'danger');
            setConfig(bak);
        });
    }, [config, setConfig]);
    React.useEffect(() => {
        getStock().then(response => {
            if (typeof response !== 'string') {
                let data: { [key: string]: StockData } = {};
                for (let i of (response.data || [])) {
                    data[i.area + '-' + i.row + '-' + i.col] = i;
                }
                setData(data);
                setConfig(response.config);
            }
        });
    }, []);
    React.useEffect(() => {
        setCrumbs(['Dashboard']);
    }, []);
    return (
        <>
            {config.map((config, idx) => (
                <MDBTable className="bg-white text-center stock-grid" bordered striped small>
                    <colgroup>
                        <col width="1" />
                        {(new Array(config.cols)).fill(1).map((_, j) => (<col key={idx + 'c' + j} />))}
                    </colgroup>
                    <MDBTableHead>
                        <tr>
                            <th>
                                {config.name || __('Area %d').replace('%d', (idx + 1).toString())}
                                <button type="button" title={__('Edit')} onClick={configRef.current?.bind(null, config, idx)}>
                                    <span className="fa fa-edit" />
                                </button>
                            </th>
                            {(new Array(config.cols)).fill(1).map((_, j) => (<th key={idx + 'h' + j}>{j + 1}</th>))}
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {(new Array(config.rows)).fill(1).map((_, i) => (
                            <tr key={idx + '-' + i}>
                                <th>{i + 1}</th>
                                {(new Array(config.cols)).fill(1).map((_, j) => {
                                    let key = idx + '-' + i + '-' + j;
                                    return (
                                        <td key={key}>
                                            {(config.disabled || []).find(t => t[0] === i && t[1] === j) ? (
                                                <button type="button" title={__('Disabled')} className="banned" onClick={doEnable.bind(null, idx, i, j)}>
                                                    <span className="fa fa-ban" />
                                                </button>
                                            ) : (data[key] ? (
                                                <>
                                                    <span>{parseSku(data[key].sku)}</span>
                                                    <button type="button" title={__('Stock Out')} onClick={doStockOut.bind(null, [[idx, i, j]])}>
                                                        <span className="fa fa-dolly" />
                                                    </button>
                                                </>
                                            ) : (
                                                <>
                                                    <span>&nbsp;</span>
                                                    <button type="button" title={__('Stock In')} onClick={doStockIn.bind(null, idx, i, j)}>
                                                        <span className="fa fa-plus" />
                                                    </button>
                                                    <button type="button" title={__('Disable')} onClick={doDisable.bind(null, idx, i, j)}>
                                                        <span className="fa fa-ban" />
                                                    </button>
                                                </>
                                            ))}
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </MDBTableBody>
                </MDBTable>
            ))}
            <Config doSave={doSaveConfig} ref={configRef} />
        </>
    );
}

type ConfigRef = (config: GridConfig, i: number) => void;

const Config = React.forwardRef<ConfigRef, { doSave: (config: GridConfig, index: number) => Promise<void> }>((props, ref) => {
    let [data, setData] = React.useState<GridConfig | null>(null);
    let [loading, setLoading] = React.useState(false);
    let [__] = useLocale();
    const index = React.useRef(-1);
    let onChange = React.useCallback((key: 'name' | 'rows' | 'cols', e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        if (data) {
            let value = e.target.value || '';
            if (key !== 'name') {
                setData({ ...data, [key]: parseFloat(value) || 0 });
            } else {
                setData({ ...data, [key]: value });
            }
        }
    }, [data, setData]);
    let onSubmit = React.useCallback((e: React.FormEvent) => {
        e.stopPropagation();
        e.preventDefault();
        let data = new FormData(e.target as HTMLFormElement);
        setLoading(true);
        props.doSave({
            name: data.get('name') as string,
            rows: parseInt((data.get('rows') || '10') as string),
            cols: parseInt((data.get('cols') || '10') as string)
        }, index.current).then(() => {
            setLoading(false);
            setData(null);
        });
    }, [data, setData, props, setLoading]);
    React.useImperativeHandle(ref, () => (
        (config, i) => {
            setData(config);
            index.current = i;
        }
    ), [setData]);
    return (
        <MDBModal open={Boolean(data)} onClose={setData.bind(null, null)} tabIndex='-1'>
            <MDBModalDialog centered size="sm">
                <MDBModalContent tag="form" onSubmit={onSubmit}>
                    <MDBModalBody>
                        <MDBInput name="name" value={data?.name || ''} label={__('Name')} className="active" onChange={onChange.bind(null, 'name')} />
                        <MDBInput name="rows" value={data?.rows || '10'} label={__('Rows')} className="active my-3" required onChange={onChange.bind(null, 'rows')} />
                        <MDBInput name="cols" value={data?.cols || '10'} label={__('Columns')} className="active" required onChange={onChange.bind(null, 'cols')} />
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn type="button" color="secondary" onClick={setData.bind(null, null)}>{__('Close')}</MDBBtn>
                        <MDBBtn type="submit" disabled={loading}>{__('Save')}</MDBBtn>
                    </MDBModalFooter>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    );
});
