import React from "react";
import Select, { OptionType } from "./select";
import { getConfig } from "../api/core";
import useLocale from "../util/i18n";

type LangSelectProps = {
    value?: string;
    required?: boolean;
    name?: string;
    label?: string;
    className?: string;
    wrapperClass?: string;
    comment?: string;
}

export default function LangSelect(props: LangSelectProps) {
    let [langs, setLangs] = React.useState<Array<OptionType>>([]);
    let [__] = useLocale();
    React.useEffect(() => {
        getConfig('i18n/languages').then(response => {
            if (typeof response !== 'string') {
                let langs = [];
                for (let l of response['i18n/languages'].split(',')) {
                    langs.push({ value: l, label: l });
                }
                setLangs(langs);
            }
        });
    }, []);
    return (
        <Select options={langs} {...props}>
            <option value="">{__('All')}</option>
        </Select>
    );
}