import request from "../util/client";
import { defaultListParams, defaultListResponse } from "./types";

export interface InventoryData {
    id: number;
    ref_id: string;
    info: {
        order_id: string;
        shipment_id: string;
        customer_name: string;
        customer_id: string;
    };
    created_at: number;
}

export interface InventoryItemData {
    inventory_id: number;
    sku: string;
}

export function getInventory(params: defaultListParams<Partial<InventoryData>> | URLSearchParams) {
    return request<defaultListResponse<InventoryData>>('inventories', 'GET', params);
}

export function getInventoryItems(params: { id: number }) {
    return request<Array<InventoryItemData>>('inventory/item', 'GET', params);
}
