import request from "../util/client";
import { defaultListParams, defaultListResponse } from "./types";

interface getCaptchaParams {
    width?: number;
    height?: number;
    length?: number;
}

export interface CaptchaData {
    image: string;
    key: string;
    exp: number;
}

export function getCaptcha(params: getCaptchaParams) {
    return request<CaptchaData>('captcha', 'GET', params);
}

export interface ResourceData {
    id: number;
    category_id: number;
    filename: string;
    path: string;
    filesize: number;
    mime: string;
    hash: string;
    status: number;
}

export function getResource(params?: defaultListParams<Partial<ResourceData>>) {
    return request<defaultListResponse<ResourceData>>('resources', 'GET', params);
}

export function uploadResource(data: FormData) {
    return request<string>('resource', 'POST', data);
}
