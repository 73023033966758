import request, { download } from "../util/client";
import { defaultListParams, defaultListResponse } from "./types";

export interface StockData {
    area: number;
    row: number;
    col: number;
    sku: string;
    qty: number;
}

export interface GridConfig {
    name?: string;
    rows: number;
    cols: number;
    disabled?: Array<[number, number]>;
}

export function getStock() {
    return request<{ data: Array<StockData>, map: string, config: Array<GridConfig> }>('stock', 'GET');
}

export function stockOut(params: { position: Array<[number, number, number]> }) {
    return request<string>('stock', 'DELETE', params);
}

export function stockIn(params: StockData) {
    return request<string>('stock', 'PUT', params);
}

export interface StockHistoryData {
    container_id: number;
    sku: string;
    qty: number;
    epcs: Array<string>
}

export function stockHistory(container?: string) {
    return request<Array<StockHistoryData>>('stock/out', 'GET', container ? { filter: { container } } : '');
}

export function exportHistory(container: string | Array<string>) {
    return download('stock/out', 'POST', { container });
}

export interface ContainerData {
    id: number;
    code: string;
    info: any;
    status: number;
    created_at: number;
}

export function getContainer(params: defaultListParams<Partial<ContainerData>> | { entries: () => IterableIterator<[string, string]> }) {
    return request<defaultListResponse<ContainerData>>('container', 'GET', params);
}
