import React from "react";
import { useCrumbs } from "../component/breadcrumbs";
import { Helmet } from "react-helmet-async";
import useLocale from "../util/i18n";
import { InventoryData, InventoryItemData, getInventory, getInventoryItems } from "../api/inventory";
import { MDBBtn, MDBCard, MDBCardHeader } from "mdb-react-ui-kit";
import { useSearchParams } from "react-router-dom";
import Pager from "../component/pager";
import { parseSku } from "../util/misc";

export default function Dashboard() {
    let [inventories, setInventories] = React.useState<Array<InventoryData>>([]);
    let [total, setTotal] = React.useState(0);
    let [search, setSearch] = useSearchParams();
    let [__] = useLocale();
    let [setCrumbs] = useCrumbs();
    React.useEffect(() => {
        getInventory(search).then(response => {
            if (typeof response !== 'string') {
                setInventories(response.data);
                setTotal(response.total);
            }
        });
    }, [search]);
    React.useEffect(() => {
        setCrumbs(['Dashboard']);
    }, []);
    return (
        <>
            <Helmet prioritizeSeoTags>
                <title>{__('Dashboard')}</title>
            </Helmet>
            <div className="dashboard">
                {typeof inventories === 'undefined' ? (
                    <div className="loading"><span className="fa fa-spin fa-spinner" /></div>
                ) : (inventories.length ? inventories.map(inventory => (
                    <Inventory key={inventory.id.toString()} inventory={inventory} />
                )) : (
                    <div className="empty">
                        <span className="far fa-calendar-xmark" />
                        <span>{__('No Data to Display')}</span>
                    </div>
                ))}
            </div>
            {inventories.length ? (<Pager total={total} search={search} onPage={setSearch} />) : null}
        </>
    );
};

function Inventory(props: { inventory: InventoryData }) {
    let [open, setOpen] = React.useState(false);
    let [items, setItems] = React.useState<Array<InventoryItemData>>();
    let [__, lang] = useLocale();
    const ref = React.useRef<HTMLUListElement>(null);
    React.useEffect(() => {
        if (open && typeof items === 'undefined') {
            getInventoryItems({ id: props.inventory.id }).then(response => {
                typeof response !== 'string' && setItems(response);
                window.setTimeout(() => ref.current && (ref.current.style.height = 'auto'), 100);
            });
        }
    }, [open, items, props.inventory]);
    return (
        <MDBCard className="inventory">
            <MDBCardHeader>
                <div className="info">
                    {props.inventory.info ? (
                        <>
                            <span><span className="label">{__('Order ID')}</span><span className="value">{props.inventory.info.order_id || ''}</span></span>
                            <span><span className="label">{__('Shipment ID')}</span><span className="value">{props.inventory.info.shipment_id || ''}</span></span>
                            <span><span className="label">{__('Customer Name')}</span><span className="value">{props.inventory.info.customer_name || ''}</span></span>
                            <span><span className="label">{__('Customer ID')}</span><span className="value">{props.inventory.info.customer_id || ''}</span></span>
                        </>
                    ) : null}
                </div>
                <div className="text-nowrap">
                    <span className="time">
                        {Intl.DateTimeFormat(lang, { dateStyle: 'short', timeStyle: 'short', hour12: false }).format(props.inventory.created_at * 1000)}
                    </span>
                    <MDBBtn className="toggler" type="button" color="link" onClick={setOpen.bind(null, !open)}>
                        <span className="fa fa-angle-down" style={{ transform: 'rotate(' + (open ? '180deg' : 0) + ')' }} />
                    </MDBBtn>
                </div>
            </MDBCardHeader>
            <ul className="card-body" ref={ref} style={open ? { height: ((ref.current?.scrollHeight || 0) + 20) + 'px', paddingTop: '10px', paddingBottom: '10px' } : { height: '0px' }}>
                {typeof items === 'undefined' ? (
                    <li className="loading"><span className="fa fa-spin fa-spinner" /></li>
                ) : (items.length ? items.map(item => (
                    <li key={item.sku}>{parseSku(item.sku)}</li>
                )) : (
                    <li className="empty">
                        <span className="far fa-calendar-xmark" />
                        <span>{__('No Data to Display')}</span>
                    </li>
                ))}
            </ul>
        </MDBCard>
    );
}
