import React from "react";
import { ContainerData, StockHistoryData, exportHistory, getContainer, stockHistory } from "../../api/stock";
import useLocale from "../../util/i18n";
import { useCrumbs } from "../../component/breadcrumbs";
import { Helmet } from "react-helmet-async";
import Pager from "../../component/pager";
import { useSearchParams } from "react-router-dom";
import { MDBBtn, MDBCard, MDBCardHeader, MDBCardTitle, MDBDropdown, MDBDropdownMenu, MDBDropdownToggle } from "mdb-react-ui-kit";
import { parseEpc, parseSku } from "../../util/misc";
import BigNumber from "bignumber.js";

export default function History() {
    let [containers, setContainers] = React.useState<Array<ContainerData>>();
    let [total, setTotal] = React.useState(0);
    let [search, setSearch] = useSearchParams();
    let [__] = useLocale();
    let [setCrumbs] = useCrumbs();
    React.useEffect(() => {
        getContainer(search).then(response => {
            if (typeof response !== 'string') {
                setContainers(response.data);
                setTotal(response.total);
            }
        });
    }, [search]);
    React.useEffect(() => {
        setCrumbs(['Stock Out History']);
    }, []);
    return (
        <>
            <Helmet prioritizeSeoTags>
                <title>{__('Stock Out History')}</title>
            </Helmet>
            <div className="dashboard">
                {typeof containers === 'undefined' ? (
                    <div className="loading"><span className="fa fa-spin fa-spinner" /></div>
                ) : (containers.length ? containers.map(item => (
                    <Item key={item.code} container={item} />
                )) : (
                    <div className="empty">
                        <span className="far fa-calendar-xmark" />
                        <span>{__('No Data to Display')}</span>
                    </div>
                ))}
            </div>
            {containers?.length ? (<Pager total={total} search={search} onPage={setSearch} />) : null}
        </>
    );
}

function Item(props: { container: ContainerData }) {
    let [open, setOpen] = React.useState(false);
    let [items, setItems] = React.useState<Array<StockHistoryData>>();
    let [__, lang] = useLocale();
    let doExport = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();
        (e.target as HTMLButtonElement).disabled = true;
        exportHistory(props.container.code).then(blob => {
            (e.target as HTMLButtonElement).disabled = false;
            const a = document.createElement('a');
            a.href = URL.createObjectURL(blob);
            a.download = 'export.xls';
            a.click();
        }, () => (e.target as HTMLButtonElement).disabled = false);
    }, [props.container]);
    const ref = React.useRef<HTMLUListElement>(null);
    React.useEffect(() => {
        if (open && typeof items === 'undefined') {
            stockHistory(props.container.code).then(response => {
                if (typeof response !== 'string') {
                    let items: Record<string, StockHistoryData> = {};
                    for (let item of response) {
                        let epc = parseEpc(item.sku);
                        item.sku = parseSku(epc);
                        if (typeof items[item.sku] === 'undefined') {
                            items[item.sku] = { ...item, epcs: [epc] };
                        } else {
                            items[item.sku].qty = (new BigNumber(item.qty)).plus(items[item.sku].qty).toNumber();
                            items[item.sku].epcs.push(epc);
                        }
                    }
                    setItems(Object.values(items));
                }
                window.setTimeout(() => ref.current && (ref.current.style.height = 'auto'), 100);
            });
        }
    }, [open, items, props.container]);
    return (
        <MDBCard className="inventory">
            <MDBCardHeader>
                <MDBCardTitle>{__('Container #%s').replace('%s', props.container.code)}</MDBCardTitle>
                <div className="d-flex align-items-center">
                    <span className="d-none d-md-block">{Intl.DateTimeFormat(lang, { dateStyle: 'medium', timeStyle: 'short', hour12: false }).format(props.container.created_at * 1000)}</span>
                    <MDBBtn className="export" type="button" color="link" onClick={doExport}>
                        <span className="fa fa-file-arrow-down" />
                        <span className="fa fa-spin fa-spinner" />
                    </MDBBtn>
                    <MDBBtn className="toggler" type="button" color="link" onClick={setOpen.bind(null, !open)}>
                        <span className="fa fa-angle-down" style={{ transform: 'rotate(' + (open ? '180deg' : 0) + ')' }} />
                    </MDBBtn>
                </div>
            </MDBCardHeader>
            <dl className="card-body" ref={ref} style={open ? { height: ((ref.current?.scrollHeight || 0) + 20) + 'px', paddingTop: '10px', paddingBottom: '10px' } : { height: '0px' }}>
                {typeof items === 'undefined' ? (
                    <dt className="loading"><span className="fa fa-spin fa-spinner" /></dt>
                ) : (items.length ? (
                    <>
                        <dt>SKU</dt>
                        <dt>{__('Quantity')}</dt>
                        {items.map(item => (
                            <React.Fragment key={item.sku}>
                                <dt title={item.epcs.join(',')}>{item.sku}</dt>
                                <dd>{parseFloat(item.qty.toString())}</dd>
                            </React.Fragment>
                        ))}
                    </>
                ) : (
                    <dt className="empty">
                        <span className="far fa-calendar-xmark" />
                        <span>{__('No Data to Display')}</span>
                    </dt>
                ))}
            </dl>
        </MDBCard>
    );
}
