import React from 'react';
import env from '../env.json';

export const locale: { lang: string, resource: { [key: string]: string }, __: (src?: string | LocaleString | null) => string } = {
    lang: env.DEFAULT_LANGUAGE,
    resource: {},
    __: (src?: string | LocaleString | null) => {
        return src ? (typeof src === 'string' ?
            (locale.resource[src] || src) :
            (src[locale.lang] || src['default'])) : '';
    }
};

function detectLanguage(lang: string | readonly string[]): [string, { [key: string]: string }] {
    let result: [string, any] = [env.DEFAULT_LANGUAGE, {}];
    if (typeof lang === 'string') {
        lang = [lang];
    }
    for (const l of lang) {
        try {
            result[1] = require('../i18n/' + l + '.json');
            result[0] = l;
            break;
        } catch {
            result[1] = {};
        }
    }
    return result;
}

export type LocaleString = {
    [lang: string]: string
};

const LocaleContext = React.createContext<[(src?: string | LocaleString | null) => string, string, (lang: string) => void]>([
    () => '', env.DEFAULT_LANGUAGE, () => { }
]);

export function LocaleProvider(props: { children: JSX.Element, init?: string }) {
    let [lang, setLang] = React.useState<string>(env.DEFAULT_LANGUAGE);
    let [resource, setResource] = React.useState<{ [key: string]: string }>({});
    let __ = React.useCallback((src?: string | LocaleString | null) => {
        return src ? (typeof src === 'string' ?
            (resource[src] || src) :
            (src[lang.replace('-', '_')] || src['default'])) : '';
    }, [resource, lang]);
    let setLanguage = React.useRef((lang: string) => {
        if (typeof localStorage !== 'undefined') {
            localStorage.setItem('lang', lang);
        }
        setLang(lang);
        try {
            setResource(require('../i18n/' + lang + '.json'));
        } catch {
            setResource({});
        }
        document.documentElement.lang = lang;
    });
    React.useEffect(() => {
        lang && (document.documentElement.lang = lang);
    }, [lang]);
    React.useEffect(() => {
        if (props.init) {
            setLanguage.current(props.init);
        } else {
            let result = detectLanguage(localStorage.getItem('language') || navigator.languages);
            locale.lang = result[0];
            setLang(result[0]);
            locale.resource = result[1];
            setResource(result[1]);
        }
    }, [props.init]);
    return (
        <LocaleContext.Provider value={[__, lang, setLanguage.current]}>
            {props.children}
        </LocaleContext.Provider>
    );
}

export default function useLocale() {
    return React.useContext(LocaleContext);
}

export function I18N(props: { children: string | LocaleString }) {
    let [__, lang] = useLocale();
    return (
        <span key={lang}>{__(props.children)}</span>
    );
}
