import React from "react";
import InputBox from "./input-box";
import useLocale, { LocaleString } from "../util/i18n";
import { OptionType } from "./select";
import { getConfig } from "../api/core";
import { Editor } from "@tinymce/tinymce-react";
import env from "../env.json";

type LocaleInputProps = {
    value?: any;
    required?: boolean;
    readOnly?: boolean;
    disabled?: boolean;
    name?: string;
    label?: string;
    className?: string;
    wrapperClass?: string;
    comment?: string;
    onChange?: (value: any) => void;
    area?: number;
}

export default function LocaleInput(props: LocaleInputProps) {
    let [value, setValue] = React.useState<LocaleString>();
    let [lang, setLang] = React.useState('default');
    let [langs, setLangs] = React.useState<Array<OptionType>>([]);
    let [__] = useLocale();
    let onChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement> | string) => {
        if (!value) {
            value = {};
        }
        value[lang] = typeof e === 'string' ? e : e.target.value;
        (props.onChange || setValue)(value);
    }, [lang, value, props.onChange]);
    React.useEffect(() => {
        setValue(props.value || {});
    }, [props.value]);
    React.useEffect(() => {
        getConfig('i18n/languages').then(response => {
            if (typeof response !== 'string') {
                let langs = [];
                for (let l of response['i18n/languages'].split(',')) {
                    langs.push({ value: l, label: l });
                }
                setLangs(langs);
            }
        });
    }, []);
    let Tag = React.useMemo<React.ComponentProps<any>>(() => props.area ? 'textarea' : 'input', [props.area]);
    return (
        <InputBox label={props.area === 2 ? undefined : props.label} required={props.required || false} className={props.wrapperClass} comment={props.comment}>
            <>
                <select
                    className={'form-control input-group-text ' + (props.className || '')}
                    value={lang}
                    onChange={e => setLang(e.target.value)}
                >
                    <option value="default">{__('Global')}</option>
                    {langs.map(option => (
                        <option key={option.value} value={option.value}>{option.label}</option>
                    ))}
                </select>
                {props.area === 2 ? (
                    <div className="px-0 col" style={{ height: 500 }}>
                        <Editor
                            tinymceScriptSrc={env.RES_URL + 'tinymce/tinymce.min.js'}
                            value={value ? (value[lang] || value['default']) : ''}
                            onEditorChange={onChange}
                            textareaName={props.name}
                            init={{
                                height: 500,
                                language: lang,
                                menubar: false,
                                plugins: [
                                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                ],
                                toolbar: 'undo redo removeformat | blocks | ' +
                                    'bold italic forecolor | alignleft aligncenter ' +
                                    'alignright alignjustify | bullist numlist image media table | ' +
                                    'code | help',
                                resize: false
                            }}
                        />
                    </div>
                ) : (
                    <>
                        <input type="hidden" name={props.name} value={JSON.stringify(value)} />
                        <Tag
                            className={'form-control active ' + (props.className || '')}
                            required={props.required} readOnly={props.readOnly} disabled={props.disabled}
                            value={value ? (value[lang] || value['default']) : ''}
                            onChange={onChange}
                        />
                    </>
                )}
            </>
        </InputBox>
    );
}
