const keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
const base64 = {
	encode: function (input: string, uriSafe = false) {
		let result;
		if (typeof Buffer !== 'undefined') {
			result = Buffer.from(input, 'binary').toString('base64');
		} else if (typeof btoa === 'function') {
			result = btoa(input);
		} else {
			let output = [];
			let chr1, chr2, chr3 = -1;
			let enc1, enc2, enc3, enc4 = -1;
			let i = 0;

			do {
				chr1 = input.charCodeAt(i++);
				chr2 = input.charCodeAt(i++);
				chr3 = input.charCodeAt(i++);

				enc1 = chr1 >> 2;
				enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
				enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
				enc4 = chr3 & 63;

				if (isNaN(chr2)) {
					enc3 = enc4 = 64;
				} else if (isNaN(chr3)) {
					enc4 = 64;
				}

				output.push(
					keyStr.charAt(enc1) +
					keyStr.charAt(enc2) +
					keyStr.charAt(enc3) +
					keyStr.charAt(enc4))
				chr1 = chr2 = chr3 = -1;
				enc1 = enc2 = enc3 = enc4 = -1;
			} while (i < input.length);
			result = output.join('');
		}

		return uriSafe ? result.replace(/[\+\/\=]/g, function (e: string) {
			return { '+': '-', '/': '_', '=': '' }[e] as string;
		}) : result;
	},

	decode: function (input: string) {
		let output = "";
		input = input.replace(/[\-\_]/g, function (e: string) {
			return { '-': '+', '_': '/' }[e] as string;
		});
		switch (Math.floor(input.length % 4)) {
			case 3: input += '='; break;
			case 2: input += '=='; break;
			case 1: input += '==='; break;
			default: ;
		}
		if (typeof Buffer !== 'undefined') {
			output = Buffer.from(input, 'base64').toString('binary');
		} else if (typeof atob === 'function') {
			output = atob(input);
		} else {
			let chr1, chr2, chr3 = -1;
			let enc1, enc2, enc3, enc4 = -1;
			let i = 0;

			do {
				enc1 = keyStr.indexOf(input.charAt(i++));
				enc2 = keyStr.indexOf(input.charAt(i++));
				enc3 = keyStr.indexOf(input.charAt(i++));
				enc4 = keyStr.indexOf(input.charAt(i++));

				if (enc1 === -1 || enc2 === -1 || enc3 === -1 || enc4 === -1) {
					return false;
				}

				chr1 = (enc1 << 2) | (enc2 >> 4);
				chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
				chr3 = ((enc3 & 3) << 6) | enc4;

				output = output + String.fromCharCode(chr1);

				if (enc3 !== 64) {
					output = output + String.fromCharCode(chr2);
				}
				if (enc4 !== 64) {
					output = output + String.fromCharCode(chr3);
				}

				chr1 = chr2 = chr3 = -1;
				enc1 = enc2 = enc3 = enc4 = -1;

			} while (i < input.length);
		}
		return output;
	}
};

export default base64;
