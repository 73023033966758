import request from "../util/client";
import { LocaleString } from "../util/i18n";
import { defaultListParams, defaultListResponse } from "./types";

interface loginParams {
    username: string;
    password: string;
    captcha: string;
    captcha_key: string;
}

interface loginResponse {
    access_token: string;
    refresh_token: string;
}

export function login(params: loginParams) {
    return request<loginResponse>('admin', 'POST', params);
}

type getOperationParams = defaultListParams<{
    code?: string;
    granted?: any;
}>;

export function getOperation(params: getOperationParams) {
    return request<defaultListResponse<{
        code: string;
        alias: string;
        name: LocaleString;
    }>>('admin/operation', 'GET', params);
}

export interface UserData {
    id: number;
    username: string;
    email: string;
    info: any;
    password?: string;
    status: number;
}

export function getUser(params: defaultListParams<Partial<UserData>>) {
    return request<defaultListResponse<UserData>>('admin/user', 'GET', params);
}

export function getMyProfile() {
    return request<UserData>('admin/me', 'GET');
}

export function saveUser(params: FormData | Partial<UserData>) {
    return request<string>('admin/user', 'PATCH', params);
}

export function deleteUser(params: { id: number }) {
    return request<string>('admin/user', 'DELETE', params);
}

export interface RoleData {
    id: number;
    parent_id: string | Array<number>;
    name: string;
    privilege: number;
}

export function getRole(params: defaultListParams<Partial<RoleData>>) {
    return request<defaultListResponse<RoleData>>('admin/role', 'GET', params);
}

export function changePassword(params: { user_id?: number, password: string }) {
    return request<{ message: string, password: string }>('admin/password', 'PATCH', params);
}
