export function parseEpc(code: string) {
    let result = '';
    for (let i = 0; i < code.length; i += 2) {
        result += String.fromCharCode(parseInt(code.substring(i, i + 2), 16));
    }
    return result.replace('\0', '');
}

export function parseSku(code: string) {
    if (!code.includes('-')) {
        code = parseEpc(code);
    }
    let parts = code.split('-');
    parts.pop();
    parts.pop();
    parts.pop();
    return parts.join('-');
}
