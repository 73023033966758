import React from 'react';
import InputBox from './input-box';
import { defaultListResponse } from '../api/types';

export type OptionType = { value: string, label: string };

type SelectProps = {
    data?: Record<string, any>;
    value?: string | Array<string>;
    defaultValue?: string | Array<string>;
    required?: boolean;
    disabled?: boolean;
    name?: string;
    label?: string;
    className?: string;
    wrapperClass?: string;
    loadWithData?: boolean;
    load?: (data?: Record<string, any>) => Promise<Array<OptionType>>;
    options?: Array<OptionType>;
    children?: JSX.Element;
    comment?: string;
} & ({ multiple: true, onChange?: (value: Array<string>) => void } | { multiple?: false, onChange?: (value: string) => void })

export function response2options(id: string, value: string, api: () => Promise<string | defaultListResponse<any>>): Promise<Array<OptionType>> {
    return new Promise(resolve => {
        api().then(response => {
            if (typeof response === 'string') {
                resolve([]);
                return;
            }
            let options: Array<OptionType> = [];
            for (let item of response.data) {
                options.push({
                    value: item[id],
                    label: item[value]
                });
            }
            resolve(options);
        }, () => resolve([]));
    });
}

export default React.forwardRef<HTMLSelectElement, SelectProps>(function Select(props, ref) {
    let [value, setValue] = React.useState<string | Array<string> | undefined>();
    let [options, setOptions] = React.useState<Array<OptionType>>([]);
    const debounce = React.useRef(false);
    const loaded = React.useRef(false);
    React.useEffect(() => {
        if (props.load && (!loaded.current || props.loadWithData)) {
            if (debounce.current) {
                return;
            }
            debounce.current = true;
            props.load(props.data).then(options => {
                setOptions(options);
                debounce.current = false;
                loaded.current = true;
            });
        }
    }, [props.load, props.data, props.loadWithData]);
    React.useEffect(() => {
        if (props.options) {
            setOptions(props.options);
        }
    }, [props.options]);
    React.useEffect(() => {
        let v = props.value;
        if (props.multiple && typeof v === 'string') {
            v = v.split(',');
        }
        setValue(v);
    }, [props.value, props.multiple]);
    const onChange = React.useCallback((e: { target: { value: string, options?: Array<HTMLOptionElement> } }) => {
        let v: string | Array<string>;
        if (props.multiple) {
            v = [];
            for (let o of (e.target.options || [])) {
                if (o.selected) {
                    v.push(o.value);
                }
            }
            (props.onChange || setValue)(v);
        } else {
            v = e.target.value;
            (props.onChange || setValue)(v);
        }
    }, [value, props.multiple, props.onChange]);
    const tmp: any = {};
    for (let i of ['defaultValue', 'required', 'name', 'multiple', 'disabled']) {
        if (typeof props[(i as 'defaultValue' | 'required' | 'name' | 'multiple' | 'disabled')] !== 'undefined') {
            tmp[i] = props[(i as 'defaultValue' | 'required' | 'name' | 'multiple' | 'disabled')];
        }
    }
    return (
        <InputBox label={props.label} required={props.required || false} className={props.wrapperClass} comment={props.comment}>
            <>
                <select
                    className={props.className ? "form-control active " + props.className : "form-control active"}
                    {...tmp}
                    value={value}
                    onChange={onChange}
                    ref={ref}>
                    {props.children}
                    {options.map((option, index) => (
                        <option value={option.value} key={tmp.name + '-' + option.value + '-' + index}>{option.label}</option>
                    ))}
                </select>
                {props.multiple ? null : (<span className="fa fa-caret-down" />)}
            </>
        </InputBox>
    );
});