import { MDBCollapse } from "mdb-react-ui-kit";
import React from "react";

export default function Accordion(props: { children: Array<JSX.Element>, className?: string }) {
    return (
        <div className={'accordion ' + (props.className || '')}>
            {props.children}
        </div>
    );
}

export function AccordionItem(props: { children: JSX.Element | Array<JSX.Element>, title: string }) {
    let [status, setStatus] = React.useState(true);
    return (
        <div className="accordion-item">
            <h2 className="accordion-header">
                <button className={'accordion-button' + (status ? '' : ' collapsed')} type="button" onClick={setStatus.bind(null, !status)}>{props.title}</button>
            </h2>
            <MDBCollapse open={status}>
                <div className="accordion-body">
                    {props.children}
                </div>
            </MDBCollapse>
        </div>
    );
}
