import React from "react";
import { MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBInput } from "mdb-react-ui-kit";
import Toast from "../component/toast";
import { usePayload } from "../util/token";
import { login } from "../api/admin";
import { CaptchaData, getCaptcha } from "../api/resource";
import useLocale from "../util/i18n";
import { Helmet } from "react-helmet-async";
import { useCrumbs } from "../component/breadcrumbs";

const width = 180, height = 72;

export default function Login() {
    let [_, setToken] = usePayload();
    let [__] = useLocale();
    let [setCrumbs] = useCrumbs();
    const [captcha, setCaptcha] = React.useState<CaptchaData | null>(null);
    const loadCaptcha = React.useRef((e?: React.MouseEvent<HTMLImageElement>) => {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }
        getCaptcha({ width, height }).then(response => typeof response !== 'string' && setCaptcha(response));
    });
    const doLogin = React.useRef<React.FormEventHandler<HTMLFormElement>>(e => {
        e.stopPropagation();
        e.preventDefault();
        if ((e.target as HTMLFormElement).checkValidity()) {
            let fd = new FormData(e.target as HTMLFormElement);
            login({ username: fd.get('username') as string, password: fd.get('password') as string, captcha_key: fd.get('captcha_key') as string, captcha: fd.get('captcha') as string }).then(response => {
                Toast.show(__('Login Successfully.'), 'success').then(() => typeof response !== 'string' && setToken(response.access_token, response.refresh_token));
            }, error => {
                loadCaptcha.current();
                Toast.show(typeof error === 'string' ? error : (error.error || error.message || __('An error detected. Please try again later.')), 'danger');
            });
        }
    });
    React.useEffect(() => {
        setCrumbs([__('Account Login')]);
    }, [__]);
    React.useEffect(() => {
        loadCaptcha.current();
    }, []);
    return (
        <div className="login-panel">
            <Helmet prioritizeSeoTags>
                <title>{__('Account Login')}</title>
            </Helmet>
            <MDBCard>
                <MDBCardBody>
                    <MDBCardTitle className="mb-4 text-center">{__('Account Login')}</MDBCardTitle>
                    <form onSubmit={doLogin.current}>
                        <MDBInput
                            type="text"
                            label={__("Username")}
                            name="username"
                            wrapperClass="mb-3"
                            spellCheck={false}
                            autoComplete="off"
                            required
                        />
                        <MDBInput
                            type="password"
                            label={__("Password")}
                            name="password"
                            wrapperClass="mb-3"
                            spellCheck={false}
                            autoComplete="off"
                            required
                        />
                        <div className="mb-3 dropdown">
                            {captcha ? (
                                <>
                                    <input type="hidden" name="captcha_key" value={captcha.key} />
                                    <img src={captcha.image} className="captcha" width={width} height={height} alt="" onClick={loadCaptcha.current} />
                                </>
                            ) : null}
                            <MDBInput
                                label={__("Captcha")}
                                name="captcha"
                                spellCheck={false}
                                autoComplete="off"
                                required
                            />
                        </div>
                        <MDBBtn type='submit' block>{__('Sign in')}</MDBBtn>
                    </form>
                </MDBCardBody>
            </MDBCard>
        </div>
    );
};
