import React from 'react';

interface InputBoxProps {
    label?: string;
    children?: JSX.Element;
    className?: string;
    required?: boolean;
    comment?: string;
}

export default function InputBox(props: InputBoxProps) {
    const labelRef = React.useRef<HTMLLabelElement>(null);
    const [label, setLabel] = React.useState('');
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabel(props.label || '');
    }, [props.label]);
    React.useEffect(() => {
        labelRef.current?.dispatchEvent(new Event('calc'));
    }, [label]);
    React.useEffect(() => {
        let l = () => {
            setLabelWidth((labelRef.current?.clientWidth || 0) * 0.8 + 8);
        };
        labelRef.current?.addEventListener('calc', l);
        return () => {
            labelRef.current?.removeEventListener('calc', l);
        };
    }, []);
    return (
        <div className={"form-outline form-input " + (props.className || '') + (props.required ? ' required' : '') + (props.comment ? ' comment' : '')}>
            {props.children}
            <label className="form-label" ref={labelRef}>{label}</label>
            <div className="form-notch">
                <div className="form-notch-leading"></div>
                <div className="form-notch-middle" style={{ width: labelWidth + "px" }}></div>
                <div className="form-notch-trailing"></div>
            </div>
        </div>
    );
}