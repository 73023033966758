import React from 'react';
import base64 from './base64';
import { locale as I18N } from './i18n';
import env from '../env.json';
import { getOperation } from '../api/admin';
import { intersection } from 'lodash';

type Payload = {
    admin: number;
    package: string;
    nickname: string;
    avatar: string;
    role: number;
    version: number;
    exp: number;
};

var token: string | null = null;
var payload: Payload | null = null;

function getToken() {
    return new Promise<string>((resolve, reject) => {
        if (token === null) {
            let v = typeof localStorage !== 'undefined' && localStorage.getItem('token');
            if (v) {
                token = v;
                payload = JSON.parse(base64.decode(token.split('.')[1]) || '{}');
                checkPayload(payload) ? resolve(token) : reject(token);
            } else {
                token = '';
                resolve(token);
            };
        } else if (token) {
            checkPayload(payload) ? resolve(token) : reject(token);
        } else {
            resolve(token);
        }
    });
}

function checkPayload(payload: Payload | null) {
    return payload && payload.exp > (new Date()).getTime() / 1000;
}

function setToken(access: string, refresh: string) {
    return new Promise<Payload>((resolve, reject) => {
        if (access) {
            token = access;
            payload = JSON.parse(base64.decode(token.split('.')[1]) || 'null') as Payload;
            typeof localStorage !== 'undefined' && localStorage.setItem('refresh_token', refresh);
            typeof localStorage !== 'undefined' && localStorage.setItem('token', access);
            resolve(payload);
        } else {
            token = '';
            reject();
        }
    });
}

function delToken(): void {
    token = '';
    payload = null;
    typeof localStorage !== 'undefined' && localStorage.removeItem('token');
    typeof window !== 'undefined' && window.location.reload();
}

function getPayload(): Payload | null {
    if (!payload && (token = typeof localStorage !== 'undefined' ? localStorage.getItem('token') : '')) {
        payload = JSON.parse(base64.decode(token.split('.')[1]) || '{}');
        if (!checkPayload(payload)) {
            if (token) {
                refreshToken(token);
            } else {
                payload = null;
                token = '';
            }
        }
    }
    return payload;
}

const PayloadContext = React.createContext<[Payload | null, (access: string, refresh: string) => void, () => void]>([null, () => { }, () => { }]);
const RbacContext = React.createContext<[(operation: string | Array<string>) => boolean, () => void]>([() => true, () => { }]);

function RbacProvider(props: { children: JSX.Element }) {
    let [granted, setGranted] = React.useState<Array<string>>([]);
    let [payload] = React.useContext(PayloadContext);
    let isGranted = React.useCallback((operation: string | Array<string>) => {
        let ops = typeof operation === 'string' ? [operation] : operation;
        return (payload?.role || 0) === 128 || intersection(granted, ops).length === ops.length;
    }, [payload, granted]);
    let doLoad = React.useCallback(() => {
        getOperation({ filter: { granted: 1 }, page: 1, limit: 1000 }).then(response => {
            if (typeof response === 'object') {
                let result: string[] = [];
                for (let i of response.data) {
                    result.push(i.code);
                }
                setGranted(result);
            }
        });
    }, [setGranted]);
    React.useEffect(() => {
        if (granted.length === 0 && (payload?.role || 0) > 128) {
            doLoad();
        }
    }, [payload]);
    return (
        <RbacContext.Provider value={[isGranted, doLoad]}>
            {props.children}
        </RbacContext.Provider>
    );
}

export function PayloadProvider(props: { children: JSX.Element }) {
    let [payload, setPayload] = React.useState<Payload | null>(getPayload());
    let set = React.useRef((access: string, refresh: string) => {
        setToken(access, refresh).then(setPayload);
    });
    let del = React.useRef(() => {
        delToken();
        setPayload(null);
        localStorage.clear();
        window.location.reload();
    });
    return (
        <PayloadContext.Provider value={[payload, set.current, del.current]}>
            <RbacProvider>
                {props.children}
            </RbacProvider>
        </PayloadContext.Provider>
    );
}

export function usePayload() {
    return React.useContext(PayloadContext);
}

export function useRbac() {
    return React.useContext(RbacContext);
}

let debounce = false;

export function refreshToken(access_token: string, retry = true): Promise<string> {
    return new Promise((resolve, reject) => {
        if (debounce) {
            let count = 0;
            let i = setInterval(() => {
                if (getPayload()?.package && token) {
                    clearInterval(i);
                    resolve(token);
                } else if (count++ >= 3) {
                    clearInterval(i);
                    reject();
                }
            }, 1000);
            return;
        }
        debounce = true;
        let refresh_token = typeof localStorage !== 'undefined' && localStorage.getItem('refresh_token');
        let fail = () => {
            typeof localStorage !== 'undefined' && localStorage.clear();
            debounce = false;
            window.location.reload();
            reject();
        };
        if (refresh_token) {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', env.BASE_URL + 'token/');
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.setRequestHeader('Accept-Language', I18N.lang);
            let rid = typeof localStorage !== 'undefined' && localStorage.getItem('rid');
            rid && xhr.setRequestHeader('X-Rid', rid);
            xhr.addEventListener('load', function () {
                let rid = xhr.getResponseHeader('X-Rid');
                rid && typeof localStorage !== 'undefined' && localStorage.setItem('rid', rid);
                if (xhr.status === 401 && retry) {
                    refreshToken(access_token, false).then(resolve, reject);
                    return;
                }
                let response: any = xhr.responseText;
                try {
                    response = JSON.parse(response);
                } catch (e) {
                    fail();
                }
                if (xhr.status < 400) {
                    setToken(response.access_token, response.refresh_token)
                    resolve(response.access_token);
                } else {
                    fail();
                }
                debounce = true;
            });
            xhr.send(JSON.stringify({ access_token, refresh_token }));
        } else {
            fail();
        }
    });
}

export { getPayload, getToken, setToken, delToken };
