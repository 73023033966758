import { getToken, refreshToken } from './token';
import env from '../env.json';
import { locale } from './i18n';

interface RequestOptions {
    headers?: { [key: string]: string };
    onprogress?: (this: XMLHttpRequest, ev: ProgressEvent<XMLHttpRequestEventTarget>) => any;
    onupload?: (this: XMLHttpRequestUpload, ev: ProgressEvent<XMLHttpRequestEventTarget>) => any;
}

type DataType = Record<string, any> | string;

export class CancelablePromise<T> extends Promise<T> {

    doCancel: (() => void) | null = null;

    constructor(exec: (resolve: (value: T | PromiseLike<T>) => void, reject: (reason?: any) => void) => void, canc: (() => void) | null = null) {
        super(exec);
        this.doCancel = canc;
    }

    cancel() {
        this.doCancel && this.doCancel();
    }

}

export function buildQuery(data: DataType): string {
    if (typeof data === 'string') {
        return data;
    }
    let params = '';
    if (('entries' in data) && typeof data.entries === 'function') {
        for (const [k, v] of data.entries()) {
            params += (params === '' ? '' : '&') + encodeURIComponent(k) + '=' + encodeURIComponent(v as string);
        }
    } else {
        for (let k in data) {
            if (Array.isArray(data[k])) {
                for (let i of data[k]) {
                    params += (params === '' ? '' : '&') + encodeURIComponent(k) + '[]=' + encodeURIComponent(i);
                }
            } else if (typeof data[k] === 'object') {
                for (let i in data[k]) {
                    if (typeof data[k][i] === 'object') {
                        for (let j in data[k][i]) {
                            params += (params === '' ? '' : '&') + encodeURIComponent(k) + '[' + i + '][' + j + ']=' + encodeURIComponent(data[k][i][j]);
                        }
                    } else {
                        params += (params === '' ? '' : '&') + encodeURIComponent(k) + '[' + i + ']=' + encodeURIComponent(data[k][i]);
                    }
                }
            } else {
                params += (params === '' ? '' : '&') + encodeURIComponent(k) + '=' + encodeURIComponent(data[k]);
            }
        }
    }
    return params;
}

export function getResponse(path: string, method = 'GET', data: DataType = {}, options: RequestOptions = {}, retry = true): CancelablePromise<Response> {
    let url = env.BASE_URL + path;
    if (url[url.length - 1] !== '/' && url.indexOf('?') === -1) {
        url += '/';
    }
    if (!options.headers) {
        options.headers = {
            'Accept': 'application/json',
            'Accept-Language': locale.lang,
            'Content-Type': 'application/json',
            'X-Store': '1'
        };
    } else {
        if (!options.headers['Accept']) {
            options.headers['Accept'] = 'application/json';
        }
        if (!options.headers['Accept-Language']) {
            options.headers['Accept-Language'] = locale.lang;
        }
        if (!options.headers['Content-Type']) {
            options.headers['Content-Type'] = 'application/json';
        }
        if (!options.headers['X-Store']) {
            options.headers['X-Store'] = '1';
        }
    }
    let rid = typeof localStorage !== 'undefined' && localStorage.getItem('rid')
    rid && (options.headers['X-Rid'] = rid);
    if (method === 'GET' || (method !== 'POST' && data instanceof FormData)) {
        let params = buildQuery(data);
        options.headers['Content-Type'] = 'application/x-www-form-urlencoded';
        if (params && method === 'GET') {
            url += (url.indexOf('?') === -1 ? '?' : '&') + params;
            data = '';
        } else {
            data = params;
        }
    } else if (data instanceof FormData || data instanceof Blob) {
        delete options.headers['Content-Type'];
    } else if (typeof data === 'object') {
        data = JSON.stringify(data);
    }
    let controller = new AbortController();
    return new CancelablePromise((resolve, reject) => {
        let headers = new Headers(options.headers);
        let doCall = () => {
            fetch(url, {
                method,
                headers,
                signal: controller.signal,
                ...(method === 'GET' || method === 'HEAD' ? {} : { body: data as string | FormData | Blob })
            }).then(response => {
                let rid = response.headers.get('X-Rid');
                rid && typeof localStorage !== 'undefined' && localStorage.setItem('rid', rid);
                if (response.status === 425 && retry) {
                    getResponse(path, method, data, options, false).then(resolve, reject);
                    return;
                }
                resolve(response);
                return;
            }, () => { });
        };
        getToken().then(token => {
            token && headers.set('Authorization', 'Bearer ' + token);
            doCall();
        }, token => {
            refreshToken(token).then(token => {
                headers.set('Authorization', 'Bearer ' + token);
                doCall();
            }, () => reject({ code: 401, message: 'Unauthorized' }));
        });
    }, () => controller.abort());
}

export function download(path: string, method = 'GET', data: DataType = {}, options: RequestOptions = {}): CancelablePromise<Blob> {
    let promise = getResponse(path, method, data, options, false);
    return new CancelablePromise((resolve, reject) => {
        promise.then(response => {
            if (response.status !== 200) {
                reject('');
                return;
            }
            response.blob().then(resolve, reject);
        }, reject);
    }, () => promise.cancel());
}

function request<T>(path: string, method = 'GET', data: DataType = {}, options: RequestOptions = {}, retry = true): CancelablePromise<T | string> {
    let promise = getResponse(path, method, data, options, retry);
    return new CancelablePromise((resolve, reject) => {
        promise.then(response => {
            if (response.status === 204) {
                resolve('');
                return;
            }
            let respond = (parsed: T | string) => {
                if (response.status < 400) {
                    resolve(parsed);
                } else {
                    reject(parsed);
                }
            };
            response.text().then((text: string) => {
                try {
                    respond(JSON.parse(text) as T);
                } catch {
                    respond(text);
                }
            });
        });
    }, () => promise.cancel());
};

export default request;
